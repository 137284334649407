var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pb-0 mb-0 pt-0", attrs: { color: "fill", flat: "" } },
    [
      _c(
        "v-container",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass: "ma-0 pa-0",
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "accent--text font-weight-normal" },
            [_vm._v(" Feature Position Management "), _c("v-spacer")],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "2" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-caption font-weight-light font-italic mx-2",
                  },
                  [_vm._v("Click Page Name to Edit")]
                ),
              ]),
              _c("v-col", { attrs: { cols: "2" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-caption font-weight-light font-italic mx-2",
                  },
                  [_vm._v("Click Feature Position to Edit")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-col",
                { staticClass: "pt-1 pb-0 px-0", attrs: { cols: "2" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "accent white--text",
                      attrs: { height: "32", flat: "" },
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "text-caption font-weight-bold" },
                        [_vm._v("Pages")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      staticClass: "pt-0",
                      style: _vm.tabsHeight,
                      attrs: {
                        vertical: "",
                        "background-color": "fill",
                        color: "primary",
                        light: "",
                        "hide-slider": "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm._l(_vm.pages, function (page, index) {
                        return _c(
                          "v-tab",
                          {
                            key: index,
                            staticClass:
                              "text-capitalize font-weight-normal text-body-2",
                            attrs: { cols: "3" },
                            on: {
                              change: function ($event) {
                                _vm.adPage = page
                              },
                            },
                          },
                          [
                            !(_vm.editPage === page)
                              ? _c(
                                  "span",
                                  {
                                    class:
                                      _vm.adPage === page
                                        ? "font-weight-bold"
                                        : "font-weight-normal",
                                  },
                                  [_vm._v(" " + _vm._s(page.name) + " ")]
                                )
                              : _vm._e(),
                            _vm.editPage === page
                              ? _c("v-text-field", {
                                  staticClass: "mx-0 pt-3 pb-0 px-0",
                                  model: {
                                    value: page.name,
                                    callback: function ($$v) {
                                      _vm.$set(page, "name", $$v)
                                    },
                                    expression: "page.name",
                                  },
                                })
                              : _vm._e(),
                            _c("v-spacer"),
                            _vm.adPage === page
                              ? _c("v-icon", [_vm._v("mdi-chevron-right")])
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-tab",
                        {
                          key: _vm.addPageField,
                          on: {
                            click: function ($event) {
                              _vm.addPageField = true
                            },
                          },
                        },
                        [
                          _vm.addPageField
                            ? _c("v-text-field", {
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    _vm.addPage(_vm.name),
                                      (_vm.addPageField = false)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { dense: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.addPageField = false
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2616324928
                                ),
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              })
                            : _vm._e(),
                          !_vm.addPageField
                            ? _c("span", [_vm._v("Add New Page")])
                            : _vm._e(),
                          !_vm.addPageField
                            ? _c("v-icon", [_vm._v("mdi-plus")])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-1 pt-0 px-0", style: _vm.colBorder },
                [
                  _c("v-data-table", {
                    staticClass: "pa-0 ma-0",
                    class: _vm.table,
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.pagePositions,
                      search: _vm.search,
                      loading: _vm.loading,
                      "item-key": "id",
                      dense: "",
                      "fixed-header": "",
                      "items-per-page": 100,
                      height: _vm.tableHeight,
                      "hide-default-footer": "",
                      "item-class": _vm.itemRowBackground,
                    },
                    on: {
                      "update:items": function ($event) {
                        _vm.pagePositions = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.name`,
                          fn: function ({ item }) {
                            return [
                              _vm.edit !== item
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.edit = item
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _vm.edit === item
                                        ? _c("v-text-field", {
                                            staticClass: "pt-2",
                                            attrs: { dense: "" },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                _vm.updateFeatPosition(item),
                                                  (_vm.edit = false)
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { dense: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              _vm.edit = {}
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "600px" },
              model: {
                value: _vm.addPosition,
                callback: function ($$v) {
                  _vm.addPosition = $$v
                },
                expression: "addPosition",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { color: "fill", fluid: "" } },
                [
                  _c("v-card-title", { staticClass: "accent--text" }, [
                    _vm._v("Add New Position"),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mx-3", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.pages,
                              "item-text": "name",
                              placeholder: "Select Page",
                              dense: "",
                              outlined: "",
                              "return-object": "",
                            },
                            model: {
                              value: _vm.newPosPage,
                              callback: function ($$v) {
                                _vm.newPosPage = $$v
                              },
                              expression: "newPosPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              placeholder: "Enter Position Name (e.g Box 11)",
                            },
                            model: {
                              value: _vm.newPosName,
                              callback: function ($$v) {
                                _vm.newPosName = $$v
                              },
                              expression: "newPosName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pb-2",
                          attrs: { dense: "", justify: "end" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.body,
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.addPosition = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text ml-2",
                              class: _vm.body,
                              attrs: { color: "save", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.addFeatPosition()
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "primary mr-6 mb-0",
                            style: _vm.floatingButton,
                            attrs: {
                              absolute: "",
                              bottom: "",
                              right: "",
                              fab: "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.addPosition = true
                              },
                            },
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Create Feature Position")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }